.admin-panel {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  input, textarea, button {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1rem;
  }
  
  textarea {
    resize: none;
  }
  
  .blog-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .blog-item {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    width: 200px;
  }
  .admin-panel {
    background-color: #fff; /* Beyaz bir arka plan */
    color: #000; /* Siyah yazı */
  }
  
  .admin-panel input, .admin-panel textarea {
    color: #000; /* Yazı renkleri açık olmalı */
    background-color: #f9f9f9; /* Açık bir arka plan */
  }
  