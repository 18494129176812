.loan-calculator {
    background-color: #f9f9f9; /* Arka plan rengi */
    border-radius: 8px; /* Kenar yuvarlama */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Gölgelendirme */
    padding: 20px; /* İçerik boşluğu */
    margin: 20px auto; /* Ortada konumlandırma */
    max-width: 400px; /* Maksimum genişlik */
    font-family: Arial, sans-serif; /* Yazı tipi */
  }
  
  .loan-calculator h5 {
    margin-bottom: 20px; /* Başlık altındaki boşluk */
    color: #333; /* Başlık rengi */
    text-align: center; /* Ortada yazma */
  }
  
  .loan-calculator .MuiTextField-root {
    margin-bottom: 10px; /* Metin alanları arasındaki boşluk */
  }
  
  .loan-calculator .MuiButton-root {
    background-color: #007bff; /* Buton arka plan rengi */
    color: #fff; /* Buton yazı rengi */
    font-weight: bold; /* Buton yazı kalınlığı */
    border-radius: 5px; /* Buton kenar yuvarlama */
  }
  
  .loan-calculator .MuiButton-root:hover {
    background-color: #0056b3; /* Hover efekti */
  }
  
  .payment-plan-table {
    margin-top: 10px; /* Ödeme planı tablosunun üst boşluğu */
    overflow-x: auto; /* Yatay kaydırma */
  }
  
  .payment-plan-table table {
    width: 100%; /* Tablonun tam genişlikte olmasını sağla */
    border-collapse: collapse; /* Kenarları birleştirme */
  }
  
  .payment-plan-table th,
  .payment-plan-table td {
    padding: 3px; /* Hücre iç boşlukları */
    border: 1px solid #ddd; /* Hücre kenar rengi */
    text-align: center; /* Hücre içeriğini ortalama */
  }
  
  .payment-plan-table th {
    background-color: #f1f1f1; /* Başlık arka plan rengi */
    color: #333; /* Başlık yazı rengi */
  }
  .text-selectable {
    -webkit-user-select: text; /* Safari için */
    user-select: text; /* Diğer tarayıcılar için */
  }
  
  .text-selectable * {
    -webkit-user-select: text; /* Safari için */
    user-select: text; /* Diğer tarayıcılar için */
  }