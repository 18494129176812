 
  
  /* financialFreedom.css */

/* Card genel ayarları */
.financial-info-card {
    padding: 16px; /* Kart içindeki boşluk */
    border-radius: 8px; /* Yuvarlak köşeler */
    background: #f5f5f5; /* Arka plan rengi */
    color: #333; /* Metin rengi */
    max-width: 600px; /* Maksimum genişlik */
    margin: auto; /* Ortalamak için */
  }
  
  .financial-info-card h6 {
    color: #f5d206; /* Başlık rengi */
    font-weight: bold; /* Kalın metin */
    font-size: 1.1rem; /* Başlık boyutu */
  }
  
  .financial-info-card p {
    font-size: 0.9rem; /* Metin boyutu */
  }
  
  .financial-info-card ul {
    padding-left: 20px; /* Liste içindeki boşluk */
    font-size: 0.9rem; /* Liste metin boyutu */
  }
  
  /* Mobil görünüm için ayarlar */
  @media (max-width: 768px) {
    .financial-info-card {
      padding: 12px; /* İç boşluk */
      max-width: 95%; /* Mobilde maksimum genişliği %95 yap */
      margin: auto; /* Ortalamak için */
    }
  
    .financial-info-card h6 {
      font-size: 1rem; /* Mobilde başlık boyutunu küçült */
    }
  
    .financial-info-card p {
      font-size: 0.8rem; /* Mobilde metin boyutunu küçült */
    }
  
    .financial-info-card ul {
      padding-left: 10px; /* Liste için sol boşluk */
    }
  }
  
  