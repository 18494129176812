/* Footer - Ziyaretçi Sayacı */
.footer {
    position: fixed; /* Pozisyonu sabit yap */
    left: 0; /* Sol tarafa sabitle */
    bottom: 0; /* Alt tarafa sabitle */
    text-align: left; /* Metni sola hizala */
    padding: 5px 10px; /* İçerik boşluğu */
   
    border-top-right-radius: 8px; /* Sağ üst köşe yuvarlat */
    z-index: 1000; /* Diğer elementlerin üzerinde görünmesi için */
    width: auto; /* Genişliği içeriğe göre ayarla */
}
.fixed-bottom-container {
    position: fixed; /* Sabit pozisyon */
    left: 0; /* Sol tarafa sabitle */
    bottom: 0; /* Alt tarafa sabitle */
    padding: 10px; /* İçerik boşluğu */
    display: flex; /* Flexbox düzeni */
    align-items: center; /* Dikey ortalama */
 
    z-index: 1000; /* Diğer elementlerin üstünde görünmesi için */
}
/* Ziyaretçi Bilgisi */
.visitor-info {
    display: flex; /* Flexbox kullan */
    align-items: center; /* Dikey olarak ortala */
    justify-content: flex-start; /* İçeriği sola hizala */
    font-size: 12px; /* Font boyutu */
    color: #464343; /* Yazı rengi */
}

/* Ziyaretçi İkonu */
.visitor-icon {
    width: 12px; /* İkon genişliği */
    height: 12px; /* İkon yüksekliği */
    margin-right: 5px; /* İkon ile yazı arasındaki boşluk */
}

/* Ziyaretçi Sayısı */
.visitor-count {
    font-size: 12px; /* Sayı font boyutu */
    color: #464343; /* Sayı rengi */
}

/* Mobil için Footer'ı sabitleme */
@media (max-width: 100px) {
    .footer {
        width: auto; /* Genişliği içeriğe göre ayarla */
        left: 0; /* Sol tarafa sabitle */
        bottom: 0; /* Alt tarafa sabitle */
        padding: 5px; /* İçerik boşluğu */
    }
}


