.blog-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .blog-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-content h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333333;
  }
  
  .blog-date {
    font-size: 0.875rem;
    color: #999999;
    margin: 10px 0;
  }
  
  .blog-content p {
    font-size: 1rem;
    color: #666666;
  }
  
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1200px;
    background-color: #1c1c1c;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    outline: none;
  }
  
  .modal h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #61dafb;
  }
  
  .modal p {
    line-height: 1.6;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  .close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #61dafb;
    color: #121212;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .close-btn:hover {
    background-color: #fff;
  }
  