.not-found-container {
    text-align: center;
    margin-top: 150px;
    color: #e2e0e0;
}

.not-found-container h1 {
    font-size: 3rem;
}

.not-found-container p {
    font-size: 1.2rem;
}
